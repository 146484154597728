import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  customers: [],
}

export const customerSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    get: (state, action) => {
      state.customers = action.payload
    },
    save: (state, action) => {
      let indexAccount = state.customers.findIndex((g)=>(g.id === action.payload.id))
      state.customers[indexAccount] = action.payload
    },
    reset: (state, action) => {
      state.customers = []
    },
  },
})

export const { get, save, reset} = customerSlice.actions
export default customerSlice.reducer