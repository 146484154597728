import { useSelector } from 'react-redux'
import { useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import Categories from './Categories'
import InventoryListPage from './InventoryList'
import Taxes from './Taxes'

const KioskInventory = () => {
  const [key, setKey] = useState('inventory');


  return <>
    <h3 className={"mb-4 mt-2"}>Inventario</h3>
    <hr />
    <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="mb-3"
    >
      <Tab eventKey="inventory" title="Inventario">
        <InventoryListPage />
      </Tab>
      <Tab eventKey="category" title="Categorías">
        <Categories />
      </Tab>
      <Tab eventKey="tax" title="Configuración de Impuestos">
        <Taxes />
      </Tab>
    </Tabs>
  </>
}

export default KioskInventory