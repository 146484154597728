import { combineReducers, configureStore } from '@reduxjs/toolkit'
import userReducer from './store/userSlice'
import guardReducer from './store/guardSlice'
import rolesReducer from './store/rolesSlice'
import permissionsReducer from './store/PermissionSlice'
import inventoryReducer from './store/InventorySlice'
import accountsReducer from './store/AccountSlice'
import recipeReducer from './store/RecipeSlice'
import orderReducer from './store/orderSlice'
import KioskInventorySlice from './store/KioskInventorySlice'
import storage from 'redux-persist/lib/storage';
import { setupListeners } from '@reduxjs/toolkit/query'

import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import CustomerSlice from './store/CustomerSlice'
import PaymentTypes from './store/PaymentTypeSlice'
import KioskCajaSlice from './store/KioskCajaSlice'
import InvoicesKioskSlice from './store/InvoicesKioskSlice'

const persistConfig = {
  key: 'shop-persist',
  storage,
}

const rootReducer = combineReducers({
  user: userReducer,
  guard: guardReducer,
  roles: rolesReducer,
  accounts: accountsReducer,
  permissions: permissionsReducer,
  inventory: inventoryReducer,
  recipes: recipeReducer,
  orders: orderReducer,
  kioskInventory: KioskInventorySlice,
  customers:  CustomerSlice,
  paymentTypes: PaymentTypes,
  kioskCaja: KioskCajaSlice,
  invoicesKiosk: InvoicesKioskSlice
})

const persistedReducer = persistReducer(persistConfig, rootReducer)


export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk]
})
setupListeners(store.dispatch)
export const persistor = persistStore(store)
