import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect, useMemo, useState } from 'react'
import axios from 'axios'
import { get as getRecipe } from '../../store/RecipeSlice'
import { get, save } from '../../store/orderSlice'
import CreateButton from '../../components/CreateButton'
import List from './List'
import ModalOption from '../../components/Modal'
import ToastAlerts from '../../components/ToastAlerts'
import { AiFillCheckCircle } from 'react-icons/ai'
import { MdError } from 'react-icons/md'
import ListProducts from './ListProducts'
import FormOrder from "./Form";
import {getMeasures} from "../../store/InventorySlice";

const OrdersPage = () => {
  const dispatch = useDispatch()
  const userState = useSelector((state) => state.user)
  const [loading, setLoading] = useState(true)
  const [show, setShow] = useState(false)
  const [showNew, setShowNew] = useState(false)
  const [guardEditable, setGuardEditable] = useState({  })
  const [toastData, setToastData] = useState({ title:"", message:"", variant:"success" })
  const [showToast, setShowToast] = useState( false )
  const closeModal = ()=> {
    setShow(false)
  }
  const closeModalNew = ()=> {
    setShowNew(false)
  }
  const configs = useMemo(()=>({
    headers:{
      'content-type': 'application/json',
      'Authorization': `Bearer ${userState.user.token}`
    }
  }), [userState])

  const getData = useCallback(async ()=>{
    const response = await axios.get(process.env.REACT_APP_USER_API_URL + "/order", configs)

    dispatch(get(response.data))
  },[dispatch, configs])

  const getDataRecipes = useCallback(async ()=>{
    const response = await axios.get(process.env.REACT_APP_USER_API_URL + "/kitchen-recipes", configs)
    dispatch(getRecipe(response.data))
  },[dispatch, configs])

  const getDataMeasures = useCallback(async ()=>{
    const response = await axios.get(process.env.REACT_APP_USER_API_URL + "/inventory-measures", configs)
    dispatch(getMeasures(response.data))
  },[dispatch, configs])

  useEffect(()=>{
    setLoading(true)
    let promise = getData()
    getDataRecipes()
    getDataMeasures()
    promise.then(()=>{
      setLoading(false)
    })
  },[getData,getDataRecipes])

  const newGuardForm = () => {
    setShowNew(true)
    setGuardEditable({
      user_id: userState.user.id,
    })
  }
  const handleEdit = (guard) => {
    setShowNew(true)
    setGuardEditable(guard)
  }
  const handleProducts = (guard) => {
    setShow(true)
    setGuardEditable(guard)
  }

  const   store = async (formData) => {
    try {
      let res
      if(guardEditable.id === undefined){
        res = await axios.post(process.env.REACT_APP_USER_API_URL + "/order", formData,configs)
      }else{
        res = await axios.put(process.env.REACT_APP_USER_API_URL + `/order/${guardEditable.id}`, formData,configs)
      }
      dispatch(save(res.data))
      getData().then(()=>{
        setToastData({
          title: <><AiFillCheckCircle className={"text-success fs-4"} /> Cambio Exitoso</>,
          message: 'Proceso ejecutado satisfactoriamente!',
          variant: 'success',
        })
        setShowToast(true)
        setShowNew(false)
      })
    }catch (e){
      setToastData({
        title: <><MdError className={"text-danger fs-4"} /> El cambio no fue exitoso</>,
        message: 'Se presentó un error en el proceso!',
        variant: 'danger',
      })
      setShowToast(true)
      console.log(e)
    }
  }

  return <>
  <h3 className={"mb-4 mt-2"}>Ordenes</h3>
    <hr />
    <List loading={loading} handleEdit={handleEdit} handleProducts={handleProducts} updateEditable={setGuardEditable} />
    <CreateButton clickEvent={newGuardForm}/>
    <ModalOption show={showNew} setClose={closeModalNew} title={"Administrar Ordenes - Nueva"}>
      <FormOrder handleSubmit={store} orderInfo={guardEditable} />
    </ModalOption>
    <ModalOption show={show} setClose={closeModal} size={"xl"} title={"Administrar Ordenes - Productos"}>
      <ListProducts orderInfo={guardEditable} reloadOrders={getData} />
    </ModalOption>
    <ToastAlerts {...toastData} show={showToast} setShowToast={setShowToast}/>
  </>
}

export default OrdersPage