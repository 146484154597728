import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  invoices: [],
}

export const InvoicesKioskSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    get: (state, action) => {
      state.invoices = action.payload
    },
    save: (state, action) => {
      let indexAccount = state.invoices.findIndex((g)=>(g.id === action.payload.id))
      state.invoices[indexAccount] = action.payload
    },
    reset: (state, action) => {
      state.invoices = []
    },
  },
})

export const { get, save, reset} = InvoicesKioskSlice.actions
export default InvoicesKioskSlice.reducer