import React, { useState } from 'react'
import * as Yup from 'yup'
import { Button, FormControl, FormGroup, FormLabel, Spinner, FormCheck } from 'react-bootstrap'
import { Field, Form, Formik } from 'formik'

const FormGuard = (props) => {
  const [formValues, setFormValues] = useState({
    name: props.name,
    active:props.active,
    dni:props.dni,
    email:props.email,
    last_name:props.last_name,
    phone_number:props.phone_number
  });
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Valor requerido"),
    active:Yup.boolean().required("Valor requerido"),
    dni:Yup.string().required("Valor requerido"),
    email:Yup.string().email().required("Valor requerido"),
    last_name:Yup.string().required("Valor requerido"),
    phone_number:Yup.string().required("Valor requerido")
  });
  const handleAutofillChange = (event) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value

    })
  }

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true)
    setFormValues(values)
    await props.handleSubmit(values)
    setSubmitting(false)
  }

  return <Formik
        initialValues={formValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, isSubmitting, errors, touched }) => (
          <Form onSubmit={handleSubmit}>
            <FormGroup className="mb-3" controlId="login-form">
              <FormLabel>Identificación</FormLabel>
              <FormControl
                type="text"
                as={Field}
                name="dni"
                controlid="dni"
                placeholder="Identificación"
                autoComplete={"off"}
                onInput={handleAutofillChange}
                isInvalid={touched.dni && !!errors.dni}
              />
              <FormControl.Feedback type="invalid">
                {errors.dni}
              </FormControl.Feedback>
            </FormGroup>
            <FormGroup className="mb-3" controlId="login-form">
              <FormLabel>Nombre</FormLabel>
              <FormControl
                type="text"
                as={Field}
                name="name"
                controlid="name"
                placeholder="Nombre"
                autoComplete={"off"}
                onInput={handleAutofillChange}
                isInvalid={touched.name && !!errors.name}
              />
              <FormControl.Feedback type="invalid">
                {errors.name}
              </FormControl.Feedback>
            </FormGroup>
            <FormGroup className="mb-3" controlId="login-form">
              <FormLabel>Apellidos</FormLabel>
              <FormControl
                type="text"
                as={Field}
                name="last_name"
                controlid="last_name"
                placeholder="Apellidos"
                autoComplete={"off"}
                onInput={handleAutofillChange}
                isInvalid={touched.last_name && !!errors.last_name}
              />
              <FormControl.Feedback type="invalid">
                {errors.last_name}
              </FormControl.Feedback>
            </FormGroup>
            <FormGroup className="mb-3" controlId="login-form">
              <FormLabel>Email</FormLabel>
              <FormControl
                type="email"
                as={Field}
                name="email"
                controlid="email"
                placeholder="Email"
                autoComplete={"off"}
                onInput={handleAutofillChange}
                isInvalid={touched.email && !!errors.email}
              />
              <FormControl.Feedback type="invalid">
                {errors.email}
              </FormControl.Feedback>
            </FormGroup>
            <FormGroup className="mb-3" controlId="login-form">
              <FormLabel>Teléfono</FormLabel>
              <FormControl
                type="phone"
                as={Field}
                name="phone_number"
                controlid="phone_number"
                placeholder="Teléfono"
                autoComplete={"off"}
                onInput={handleAutofillChange}
                isInvalid={touched.phone_number && !!errors.phone_number}
              />
              <FormControl.Feedback type="invalid">
                {errors.phone_number}
              </FormControl.Feedback>
            </FormGroup>
            <FormGroup className="mb-3" controlId="active-account-form">
              <FormLabel>Estado</FormLabel>
              <Field name="active">
                {({ field, form }) => (
                  <FormCheck
                    {...field}
                    type="switch"
                    id="active"
                    name="active"
                    controlid="active"
                    placeholder="active"
                    value={field.value}
                    defaultChecked={field.value!==0}
                    onChange={(event) => {
                      form.setFieldValue(event.target.name, event.target.checked);
                    }}
                    autoComplete={"off"}
                    onInput={handleAutofillChange}
                    isInvalid={touched.active && !!errors.active}
                  />
                )}
              </Field>
              <FormControl.Feedback type="invalid">
                {errors.active}
              </FormControl.Feedback>
            </FormGroup>
            <Button variant="success" type="submit" disabled={isSubmitting}>
              {isSubmitting && <Spinner />}
              Almacenar
            </Button>
          </Form>
        )}
      </Formik>

}

export default FormGuard