import { useSelector } from 'react-redux'
import { Link, redirect, Route, Routes } from 'react-router-dom'
import { Nav } from 'react-bootstrap'
import LoginPage from './pages/Login'
import { useDispatch } from 'react-redux'
import Guards from './pages/Guards'
import Roles from './pages/Roles'
import Accounts from './pages/Account'
import Permissions from './pages/Permissions'
import Inventory from './pages/Inventory'
import RecipePage from './pages/Recipes'
import OrdersPage from './pages/Orders'
import { logout } from './store/userSlice'
import { reset as resetGuards} from './store/guardSlice'
import { reset as resetRoles} from './store/rolesSlice'
import { reset as resetPermissions} from './store/PermissionSlice'
import { reset as resetAccount} from './store/AccountSlice'
import { reset as resetInventory} from './store/InventorySlice'
import { reset as resetOrders} from './store/orderSlice'
import { reset as resetRecipes} from './store/RecipeSlice'
import KioskInventory from './pages/KioskInventory'
import CustomerPage from './pages/Customers'
import { reset as resetCustomers } from './store/CustomerSlice'
import PaymentTypes from './pages/PaymentTypes'
import KioskCaja from './pages/KioskCaja'

let routes = [
  {
    'title': 'Home',
    'path': '/',
    'page': <h1>Home</h1>,
    'permissions': [],
    'roles': [ "root" ]
  },
  {
    'title': 'Login',
    'path': '/login',
    'page': <LoginPage />,
    'permissions': [],
    'roles': []
  },
  {
    'title': 'Applications',
    'path': '/apps',
    'page': <Guards />,
    'permissions': ['guard.list'],
    'roles': [],
  },
  {
    'title': 'Roles',
    'path': '/roles',
    'page': <Roles />,
    'permissions': ['rol.list'],
    'roles': [''],
  },
  {
    'title': 'Usuarios',
    'path': '/users',
    'page': <Accounts />,
    'permissions': ['user.list'],
    'roles': [''],
  },
  {
    'title': 'Permisos',
    'path': '/permissions',
    'page': <Permissions />,
    'permissions': ['permission.list'],
    'roles': [''],
  },
  {
    'title': 'Inventario',
    'path': '/inventory',
    'page': <Inventory />,
    'permissions': ['category.list','inventory-type.list','input.list','batch.list','measures.list'],
    'roles': [''],
  },
  {
    'title': 'Recetas',
    'path': '/recipes',
    'page': <RecipePage />,
    'permissions': ['recipes.list'],
    'roles': [],
  },
  {
    'title': 'Ordenes',
    'path': '/orders',
    'page': <OrdersPage />,
    'permissions': ['order.list'],
    'roles': [],
  },

  {
    'title': 'Kiosko Inventario',
    'path': '/kiosk/inventory',
    'page': <KioskInventory />,
    'permissions': ['kiosk_categories.list','kiosk_products.list', 'tax.list'],
    'roles': [],
  },
  {
    'title': 'Formas de Pago',
    'path': '/payment-types',
    'page': <PaymentTypes />,
    'permissions': ['paymenttypes.list'],
    'roles': [],
  },
  {
    'title': 'Clientes',
    'path': '/customer',
    'page': <CustomerPage />,
    'permissions': ['clientes.list'],
    'roles': [],
  },
  {
    'title': 'Caja Kiosko',
    'path': '/caja-kiosko',
    'page': <KioskCaja />,
    'permissions': ['compras.list'],
    'roles': [],
  }
]

const GetRoutes = () =>{
  const userState = useSelector((state) => state.user)
  if(userState.user != null && userState.user.data !== undefined ){
    let loggedRoutes = []
    let permissionRoutes = []
    let rolRoutes = []
    let rolPermissionRoutes = []
    loggedRoutes = routes.map((r) =>  r.roles[0]==="*" ? r : null)
    if(userState.user !== null && userState.user.data.permissions !== undefined && userState.user.data.permissions.length > 0){
      const permissionFilter = (permission)=>(userState.user.data.permissions.includes(permission))
      permissionRoutes = routes.map((r) =>  r.permissions.some(permissionFilter)?r:null)
    }else if(userState.user !== null && userState.user.data.roles !== undefined && userState.user.data.roles.length > 0){
      const permissionsUser = userState.user.data.roles.reduce((acc, obj) => {
        acc.push(obj.permissions.flat(1));
        return acc;
      }, []).flat(1);

      const rolPermissionFilter = (permission)=> {
        return (permissionsUser.find((r)=> r.name === permission))
      }
      rolPermissionRoutes = routes.map((r) => r.permissions.some(rolPermissionFilter)?r:null)
      const rolFilter = (rol)=> (userState.user.data.roles.find((r)=> r.name === rol))
      rolRoutes = routes.map((r) => r.roles.some(rolFilter)?r:null)
    }
    return   <Routes>
       {loggedRoutes.map((r, idx)=> (r!= null)?<Route key={`path-${idx}`} path={r.path} exact element={r.page} />:null )}
      {rolRoutes.map((r, idx)=> (r!= null)?<Route key={`path-${idx}`} path={r.path} exact element={r.page} />:null )}
      {rolPermissionRoutes.map((r, idx)=> (r!= null)?<Route key={`path-${idx}`} path={r.path} exact element={r.page} />:null )}
      {permissionRoutes.map((r, idx)=> (r!= null)?<Route key={`path-${idx}`} path={r.path} exact element={r.page} />:null )}
      <Route path='*' exact={true} element={<h1>Not found</h1>} />
    </Routes>
  }else{
      return   <Routes>
        {routes.map((r,idx) => {
          if(r.permissions.length === 0 && r.roles.length === 0){
            return <Route key={`path-${idx}`} path={r.path} exact element={r.page} />
          }
          return null
        })
        }
        <Route path='*' exact={true} element={<LoginPage />} />
      </Routes>
    }
  }

const GetMenu = () =>{
  const dispatch = useDispatch()
  const userState = useSelector((state) => state.user)
  if(userState.user != null && userState.user.data !== undefined ){
    let permissionRoutes = []
    let rolRoutes = []
    let rolPermissionRoutes = []
    const loggedRoutes = routes.map((r) =>  r.roles[0]==="*" ? r : null)
    if(userState.user !== null && userState.user.data.permissions !== undefined && userState.user.data.permissions.length > 0){
      const permissionFilter = (permission)=>(userState.user.data.permissions.includes(permission))
      permissionRoutes = routes.map((r) =>  r.permissions.some(permissionFilter)?r:null)
    }else if(userState.user !== null && userState.user.data.roles !== undefined && userState.user.data.roles.length > 0){
      const permissionsUser = userState.user.data.roles.reduce((acc, obj) => {
        acc.push(obj.permissions.flat(1));
        return acc;
      }, []).flat(1);

      const rolPermissionFilter = (permission)=> {
        return (permissionsUser.find((r)=> r.name === permission))
      }
      rolPermissionRoutes = routes.map((r) => r.permissions.some(rolPermissionFilter)?r:null)
      const rolFilter = (rol)=> (userState.user.data.roles.find((r)=> r.name === rol))
      rolRoutes = routes.map((r) => r.roles.some(rolFilter)?r:null)
    }
    return   <>
      {loggedRoutes.map((r, idx)=> (r!= null)?<Nav.Link as={Link} key={`path-${idx}`} to={r.path} >{r.title}</Nav.Link>:null )}
      {rolRoutes.map((r, idx)=> (r!= null)?<Nav.Link as={Link} key={`path-${idx}`} to={r.path} >{r.title}</Nav.Link>:null )}
      {rolPermissionRoutes.map((r, idx)=> (r!= null)?<Nav.Link as={Link} key={`path-${idx}`} to={r.path} >{r.title}</Nav.Link>:null )}
      {permissionRoutes.map((r, idx)=> (r!= null)?<Nav.Link as={Link} key={`path-${idx}`} to={r.path} >{r.title}</Nav.Link>:null )}
      <Nav.Link onClick={() => {
        dispatch(logout())
        dispatch(resetGuards())
        dispatch(resetRoles())
        dispatch(resetPermissions())
        dispatch(resetAccount())
        dispatch(resetInventory())
        dispatch(resetRecipes())
        dispatch(resetOrders())
        dispatch(resetCustomers())
        return redirect("/login");
      }}>Cerrar Sesión</Nav.Link>
    </>
  } else{
    return   <>
      {routes.map((r,idx) => {
        if(r.permissions.length === 0 &&  r.roles.length === 0){
          return <Nav.Link as={Link} key={`path-${idx}`} to={r.path} >{r.title}</Nav.Link>
        }
        return null
      })
      }
    </>
  }
}

export { GetRoutes, GetMenu }