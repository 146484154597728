import React, { useState } from 'react'
import * as Yup from 'yup'
import { Button, FormControl, FormGroup, FormLabel, Spinner } from 'react-bootstrap'
import { Field, Form, Formik } from 'formik'
import { useSelector } from 'react-redux'

const FormOrder = (props) => {
  const userState = useSelector((state) => state.user)
  const [formValues, setFormValues] = useState({
    user_id: userState.user.data.id,
    external_reference: props.orderInfo.external_reference,
    price: props.orderInfo.price
  });

  const validationSchema = Yup.object().shape({
    external_reference: Yup.string().required("Valor requerido").max(20, "Referencia de compra"),
    price: Yup.number().required("Valor requerido").min(0)
  });
  const handleAutofillChange = (event) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value
    })
  }

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true)
    setFormValues(values)
    await props.handleSubmit(values)
    setSubmitting(false)
  }

  return <Formik
        initialValues={formValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, isSubmitting, errors, touched }) =>
        {
          return <Form onSubmit={handleSubmit}>
            <FormGroup className="mb-3" controlId="login-form">
              <FormLabel>Refencia Externa</FormLabel>
              <FormControl
                type="text"
                as={Field}
                name="external_reference"
                controlid="external_reference"
                placeholder="Referencia Externa"
                autoComplete={"off"}
                onInput={handleAutofillChange}
                isInvalid={touched.external_reference && !!errors.external_reference}
              />
              <FormControl.Feedback type="invalid">
                {errors.external_reference}
              </FormControl.Feedback>
            </FormGroup>
            <FormGroup className="mb-3" controlId="price-form">
              <FormLabel>Precio</FormLabel>
              <FormControl
                type="number"
                as={Field}
                name="price"
                controlid="price"
                placeholder="Precio de Venta"
                autoComplete={"off"}
                onInput={handleAutofillChange}
                isInvalid={touched.price && !!errors.price}
              />
              <FormControl.Feedback type="invalid">
                {errors.price}
              </FormControl.Feedback>
            </FormGroup>

            {isSubmitting && <Spinner />}
            <Button variant="success" type="submit" disabled={isSubmitting}>
              {isSubmitting && <Spinner />}
              Almacenar
            </Button>
          </Form>
        }
        }
      </Formik>

}

export default FormOrder