import { Button, Container, Spinner, Table } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { FaBan, FaCheckCircle } from "react-icons/fa";

const List =(props) =>{
  const customerState = useSelector((state) => state.customers)
  const handleEdit = (evt) => {
    const indexGuard = evt.target.dataset.index;
    props.handleEdit(customerState.customers[indexGuard])
  }

  if(props.loading){
    return <Container className={"text-center justify-content-center"}>
      <Spinner className={"text-center"} animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </Container>
  }
  return <Table striped bordered hover responsive>
    <thead>
    <tr>
      <th>ID</th>
      <th>DNI</th>
      <th>Nombre</th>
      <th>Email</th>
      <th>Teléfono</th>
      <th>Estado</th>
      <th>Acciones</th>
    </tr>
    </thead>
    <tbody>
    {customerState !== undefined && customerState.customers.map((g, idx)=>(<tr key={idx}>
      <td>{g.id}</td>
      <td>{g.dni}</td>
      <td>{g.name} {g.last_name}</td>
      <td>{g.email}</td>
      <td>{g.phone_number}</td>
      <td>{g.active ? <FaCheckCircle className="text-success" />: <FaBan className="text-danger" /> }</td>
      <td><Button variant={"warning"} data-index={idx} className={"text-light"} onClick={handleEdit}>Editar</Button></td>
    </tr>))}
    </tbody>
  </Table>
}

export default List