import React, { useCallback, useEffect, useMemo, useState } from 'react'
import * as Yup from 'yup'
import { Button, FormControl, FormGroup, FormLabel, Spinner } from 'react-bootstrap'
import { Field, Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { getMeasures } from '../../../store/InventorySlice'

const FormGuard = (props) => {
  const guardState = useSelector((state) => state.inventory)
  const dispatch = useDispatch()
  const userState = useSelector((state) => state.user)
  const [formValues, setFormValues] = useState({
    origin_id: props.origin_id,
    destination_id: props.destination_id,
    factor: props.factor,
  });
  const configs = useMemo(()=>({
    headers:{
      'content-type': 'application/json',
      'Authorization': `Bearer ${userState.user.token}`
    }
  }), [userState])

  const getData = useCallback(async ()=>{
    const response = await axios.get(process.env.REACT_APP_USER_API_URL + "/inventory-measures", configs)

    dispatch(getMeasures(response.data))
  },[dispatch, configs])

  useEffect(()=>{
    getData(userState.user.token)
  },[getData,userState.user.token])

  const validationSchema = Yup.object().shape({
    "origin_id":Yup.string().required("Valor requerido"),
    "destination_id":Yup.string().required("Valor requerido"),
    "factor":Yup.string().required("Valor requerido"),
  });
  const handleAutofillChange = (event) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value
    })
  }

  const handleSubmit = async (values, { setSubmitting , isSubmitting}) => {
    setSubmitting(true)
    setFormValues(values)
    await props.handleSubmit(values)
    setSubmitting(false)
  }

  return <Formik
    initialValues={formValues}
    validationSchema={validationSchema}
    onSubmit={handleSubmit}
  >
    {({ handleSubmit, isSubmitting, errors, touched }) =>
    {
      return <Form onSubmit={handleSubmit}>
        <FormGroup className="mb-3" controlId="guard-name-origin_id-form">
          <FormLabel>Unidad de medida origen</FormLabel>
          <Field
            name="origin_id"
            isInvalid={touched.origin_id && !!errors.origin_id}
          >
            {({ field, form }) => (
              <select
                className={"form-control"}
                value={field.value}
                controlid="origin_id"
                placeholder="Unidad de medida de origen"
                autoComplete={"off"}
                onChange={(event) => {
                  form.setFieldValue(field.name, event.target.value);
                }}
              >
                <option value="">Seleccione un tipo</option>
                {guardState !== undefined && guardState.measure.map((g, idx)=> (<option key={`opti-${idx}`} value={g.id}>{g.name}</option>))}
              </select>
            )}
          </Field>
          <FormControl.Feedback type="invalid">
            {errors.origin_id}
          </FormControl.Feedback>
        </FormGroup>
        <FormGroup className="mb-3" controlId="guard-name-dest-form">
          <FormLabel>Unidad de medida destino</FormLabel>
          <Field
            name="destination_id"
            isInvalid={touched.destination_id && !!errors.destination_id}
          >
            {({ field, form }) => (
              <select
                className={"form-control"}
                value={field.value}
                controlid="destination_id"
                placeholder="Unidad de medida de destino"
                autoComplete={"off"}
                onChange={(event) => {
                  form.setFieldValue(field.name, event.target.value);
                }}
              >
                <option value="">Seleccione un tipo</option>
                {guardState !== undefined && guardState.measure.map((g, idx)=> (<option key={`opti-${idx}`} value={g.id}>{g.name}</option>))}
              </select>
            )}
          </Field>
          <FormControl.Feedback type="invalid">
            {errors.destination_id}
          </FormControl.Feedback>
        </FormGroup>
        <FormGroup className="mb-3" controlId="-form">
          <FormLabel>Factor</FormLabel>
          <FormControl
            type="number"
            as={Field}
            name="factor"
            controlid="factor"
            placeholder="Factor "
            autoComplete={"off"}
            onInput={handleAutofillChange}
            isInvalid={touched.factor && !!errors.factor}
          />
          <FormControl.Feedback type="invalid">
            {errors.factor}
          </FormControl.Feedback>
        </FormGroup>
        {isSubmitting && <Spinner />}
        <Button variant="success" type="submit" disabled={isSubmitting}>
          Almacenar
        </Button>
      </Form>
    }
    }
  </Formik>

}

export default FormGuard