import { Badge, Button, Container, Spinner, Table } from 'react-bootstrap'
import { useSelector } from 'react-redux'

const List =(props) =>{
  const permissionsState = useSelector((state) => state.inventory)
  const handleEdit = (evt) => {
    const indexGuard = evt.target.dataset.index;
    props.handleEdit(permissionsState.input[indexGuard])
  }
  const handleShowList = (evt) => {
    const indexGuard = evt.target.dataset.index;
    props.handleShowList(permissionsState.input[indexGuard])
  }
  if(props.loading){
    return <Container className={"text-center justify-content-center"}>
      <Spinner className={"text-center"} animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </Container>
  }
  return <Table striped bordered hover responsive>
    <thead>
    <tr>
      <th>ID</th>
      <th>Tipo</th>
      <th>Categoría</th>
      <th>Serial</th>
      <th>Nombre</th>
      <th>Cantidad minima en stock</th>
      <th>Estado</th>
      <th>Acciones</th>
    </tr>
    </thead>
    <tbody>
    {permissionsState !== undefined && permissionsState.input.map((g, idx)=>(<tr key={idx}>
      <td>{g.id}</td>
      <td>{g.category.input_types.name}</td>
      <td>{g.category.name}</td>
      <td>{g.serial}</td>
      <td>{g.name}</td>
      <td>{g.min_inventory} {g.measure.name}(s)</td>
      <td><Badge bg={g.active=="1"?"success":"danger"}>{g.active=="1"?"activo":"inactivo"}</Badge></td>
      <td>
        <Button variant={"info"} data-index={idx} className={"text-light mx-1"} onClick={handleShowList}>Ver Unidades</Button>
        <Button variant={"warning"} data-index={idx} className={"text-light  mx-1"} onClick={handleEdit}>Editar</Button>
      </td>
    </tr>))}
    </tbody>
  </Table>
}

export default List