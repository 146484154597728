import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  category: [],
  products: [],
  units: [],
  taxes: [],
}

export const KioskInventorySlice = createSlice({
  name: 'kioskinventory',
  initialState,
  reducers: {
    getCategories: (state, action) => {
      state.category = action.payload
    },
    saveCategory: (state, action) => {
      let index = state.category.findIndex((g)=>(g.id === action.payload.id))
      state.category[index] = action.payload
    },
    getProduct: (state, action) => {
      state.products = action.payload
    },
    getUnits: (state, action) => {
      state.units = action.payload
    },
    getTaxes: (state, action) => {
      state.taxes = action.payload
    },
    reset: (state, action) => {
      state.category = []
      state.products = []
      state.units = []
      state.taxes = []
    },
  },
})

export const {
  getCategories,
  saveCategory,
  getProduct,
  getUnits,
  getTaxes,
  reset
} = KioskInventorySlice.actions
export default KioskInventorySlice.reducer