import React, { useCallback, useEffect, useMemo, useState } from 'react'
import * as Yup from 'yup'
import { Button, FormCheck, FormControl, FormGroup, FormLabel, Spinner } from 'react-bootstrap'
import { Field, Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { getCategories, getMeasures } from '../../../store/InventorySlice'

const FormGuard = (props) => {
  const guardState = useSelector((state) => state.inventory)
  const dispatch = useDispatch()
  const userState = useSelector((state) => state.user)
  const [formValues, setFormValues] = useState({
    name: props.name,
    category_id: props.category_id,
    measure_id: props.measure_id,
    serial: props.serial,
    active: props.active,
    min_inventory: props.min_inventory
  });

  const configs = useMemo(()=>({
    headers:{
      'content-type': 'application/json',
      'Authorization': `Bearer ${userState.user.token}`
    }
  }), [userState])

  const getData = useCallback(async ()=>{
    const response = await axios.get(process.env.REACT_APP_USER_API_URL + "/inventory-categories", configs)

    dispatch(getCategories(response.data))
  },[dispatch, configs])

  const getDataB = useCallback(async ()=>{
    const response = await axios.get(process.env.REACT_APP_USER_API_URL + "/inventory-measures", configs)

    dispatch(getMeasures(response.data))
  },[dispatch, configs])

  useEffect(()=>{
    getData(userState.user.token)
    getDataB(userState.user.token)
  },[getData,getDataB,userState.user.token])


  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Valor requerido"),
    serial: Yup.string().required("Valor requerido").max(20, "Nombre debe ser máximo de 20 caracteres"),
    active: Yup.boolean().required("Valor requerido"),
    category_id: Yup.string().required("Valor requerido"),
    measure_id: Yup.string().required("Valor requerido"),
    min_inventory: Yup.number().required("Valor requerido").min(0)
  });
  const handleAutofillChange = (event) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value
    })
  }

  const handleSubmit = async (values, { setSubmitting , isSubmitting}) => {
    setSubmitting(true)
    setFormValues(values)
    await props.handleSubmit(values)
    setSubmitting(false)
  }

  return <Formik
        initialValues={formValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, isSubmitting, errors, touched }) =>
        {
          return <Form onSubmit={handleSubmit}>
            <FormGroup className="mb-3" controlId="login-form">
              <FormLabel>Nombre del Insumo</FormLabel>
              <FormControl
                type="text"
                as={Field}
                name="name"
                controlid="name"
                placeholder="Nombre"
                autoComplete={"off"}
                onInput={handleAutofillChange}
                isInvalid={touched.name && !!errors.name}
              />
              <FormControl.Feedback type="invalid">
                {errors.name}
              </FormControl.Feedback>
            </FormGroup>
            <FormGroup className="mb-3" controlId="login-form">
              <FormLabel>Serial del Insumo</FormLabel>
              <FormControl
                type="text"
                as={Field}
                name="serial"
                controlid="serial"
                placeholder="Serial"
                autoComplete={"off"}
                onInput={handleAutofillChange}
                isInvalid={touched.serial && !!errors.serial}
              />
              <FormControl.Feedback type="invalid">
                {errors.serial}
              </FormControl.Feedback>
            </FormGroup>
            <FormGroup className="mb-3" controlId="min-inventory-form">
              <FormLabel>Cantidad minima en stock</FormLabel>
              <FormControl
                  type="number"
                  as={Field}
                  name="min_inventory"
                  controlid="min_inventory"
                  placeholder="Cantidad minima en stock"
                  autoComplete={"off"}
                  onInput={handleAutofillChange}
                  isInvalid={touched.min_inventory && !!errors.min_inventory}
              />
              <FormControl.Feedback type="invalid">
                {errors.min_inventory}
              </FormControl.Feedback>
            </FormGroup>
            <FormGroup className="mb-3" controlId="active-account-form">
              <FormLabel>Estado</FormLabel>
              <Field name="active">
                {({ field, form }) => (
                  <FormCheck
                    {...field}
                    type="switch"
                    id="active"
                    name="active"
                    controlid="active"
                    placeholder="active"
                    value={field.value}
                    defaultChecked={field.value!==0}
                    onChange={(event) => {
                      form.setFieldValue(event.target.name, event.target.checked);
                    }}
                    autoComplete={"off"}
                    onInput={handleAutofillChange}
                    isInvalid={touched.active && !!errors.active}
                  />
                )}
              </Field>
              <FormControl.Feedback type="invalid">
                {errors.active}
              </FormControl.Feedback>
            </FormGroup>
            <FormGroup className="mb-3" controlId="guard-name-account-form">
              <FormLabel>Categoría</FormLabel>
              <Field
                name="category_id"
                isInvalid={touched.guard_name && !!errors.guard_name}
              >
                {({ field, form }) => (
                  <select
                    className={"form-control"}
                    value={field.value}
                    controlid="category_id"
                    placeholder="Categoría"
                    autoComplete={"off"}
                    onChange={(event) => {
                      form.setFieldValue(field.name, event.target.value);
                    }}
                  >
                    <option value="">Seleccione un tipo</option>
                    {guardState !== undefined && guardState.category.map((g, idx)=> (<option key={`opti-${idx}`} value={g.id}>{g.name}</option>))}
                  </select>
                )}
              </Field>
              <FormControl.Feedback type="invalid">
                {errors.category_id}
              </FormControl.Feedback>
            </FormGroup>
            <FormGroup className="mb-3" controlId="guard-name-account-form">
              <FormLabel>Unidad de medida</FormLabel>
              <Field
                name="measure_id"
                isInvalid={touched.measure_id && !!errors.measure_id}
              >
                {({ field, form }) => (
                  <select
                    className={"form-control"}
                    value={field.value}
                    controlid="measure_id"
                    placeholder="Categoría"
                    autoComplete={"off"}
                    onChange={(event) => {
                      form.setFieldValue(field.name, event.target.value);
                    }}
                  >
                    <option value="">Seleccione un tipo</option>
                    {guardState !== undefined && guardState.measure.map((g, idx)=> (<option key={`opti-${idx}`} value={g.id}>{g.name}</option>))}
                  </select>
                )}
              </Field>
              <FormControl.Feedback type="invalid">
                {errors.measure_id}
              </FormControl.Feedback>
            </FormGroup>
            {isSubmitting && <Spinner />}
            <Button variant="success" type="submit" disabled={isSubmitting}>
              Almacenar
            </Button>
          </Form>
        }
        }
      </Formik>

}

export default FormGuard