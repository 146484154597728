import React, { useCallback, useEffect, useMemo, useState } from 'react'
import * as Yup from 'yup'
import { Button, FormCheck, FormControl, FormGroup, FormLabel, Spinner } from 'react-bootstrap'
import { Field, Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { getCategories, getTaxes } from '../../../store/KioskInventorySlice'

const FormGuard = (props) => {
  const kioskInventoryState = useSelector((state) => state.kioskInventory)
  const dispatch = useDispatch()
  const userState = useSelector((state) => state.user)
  const [formValues, setFormValues] = useState({
    name: props.name,
    category_id: props.category_id,
    tax_id: props.tax_id,
    code: props.code,
    description: props.description,
    sale_price: props.sale_price,
    active: props.active,
  });
console.log(kioskInventoryState)
  const configs = useMemo(()=>({
    headers:{
      'content-type': 'application/json',
      'Authorization': `Bearer ${userState.user.token}`
    }
  }), [userState])

  const getData = useCallback(async ()=>{
    const response = await axios.get(process.env.REACT_APP_USER_API_URL + "/kiosk/category", configs)

    dispatch(getCategories(response.data))
  },[dispatch, configs])

  const getDataTaxes = useCallback(async ()=>{
    const response = await axios.get(process.env.REACT_APP_USER_API_URL + "/kiosk/tax", configs)
    dispatch(getTaxes(response.data))
  },[dispatch, configs])

  useEffect(()=>{
    getData().then((r)=>{
      getDataTaxes()
    })
  },[getData])


  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Valor requerido"),
    code: Yup.string().required("Valor requerido").max(20, "debe ser máximo de 20 caracteres"),
    active: Yup.boolean().required("Valor requerido"),
    category_id: Yup.string().required("Valor requerido"),
    description: Yup.string().required("Valor requerido"),
    active: Yup.boolean().required("Valor requerido")
  });
  const handleAutofillChange = (event) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value
    })
  }

  const handleSubmit = async (values, { setSubmitting , isSubmitting}) => {
    setSubmitting(true)
    setFormValues(values)
    await props.handleSubmit(values)
    setSubmitting(false)
  }

  return <Formik
        initialValues={formValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, isSubmitting, errors, touched }) =>
        {
          return <Form onSubmit={handleSubmit}>
            <FormGroup className="mb-3" controlId="login-form">
              <FormLabel>Nombre del Producto</FormLabel>
              <FormControl
                type="text"
                as={Field}
                name="name"
                controlid="name"
                placeholder="Nombre"
                autoComplete={"off"}
                onInput={handleAutofillChange}
                isInvalid={touched.name && !!errors.name}
              />
              <FormControl.Feedback type="invalid">
                {errors.name}
              </FormControl.Feedback>
            </FormGroup>
            <FormGroup className="mb-3" controlId="login-form">
              <FormLabel>Serial/Código</FormLabel>
              <FormControl
                type="text"
                as={Field}
                name="code"
                controlid="code"
                placeholder="Serial"
                autoComplete={"off"}
                onInput={handleAutofillChange}
                isInvalid={touched.code && !!errors.code}
              />
              <FormControl.Feedback type="invalid">
                {errors.code}
              </FormControl.Feedback>
            </FormGroup>
            <FormGroup className="mb-3" controlId="login-form">
              <FormLabel>Descripción del Producto</FormLabel>
              <FormControl
                type="text"
                as={Field}
                name="description"
                controlid="description"
                placeholder="Descripción "
                autoComplete={"off"}
                onInput={handleAutofillChange}
                isInvalid={touched.description && !!errors.description}
              />
              <FormControl.Feedback type="invalid">
                {errors.description}
              </FormControl.Feedback>
            </FormGroup>
            <FormGroup className="mb-3" controlId="login-form">
              <FormLabel>Precio Venta</FormLabel>
              <FormControl
                type="number"
                as={Field}
                name="sale_price"
                controlid="sale_price"
                placeholder="Precio de venta"
                autoComplete={"off"}
                onInput={handleAutofillChange}
                isInvalid={touched.sale_price && !!errors.sale_price}
              />
              <FormControl.Feedback type="invalid">
                {errors.sale_price}
              </FormControl.Feedback>
            </FormGroup>
            <FormGroup className="mb-3" controlId="active-account-form">
              <FormLabel>Estado</FormLabel>
              <Field name="active">
                {({ field, form }) => (
                  <FormCheck
                    {...field}
                    type="switch"
                    id="active"
                    name="active"
                    controlid="active"
                    placeholder="active"
                    value={field.value}
                    defaultChecked={field.value!==0}
                    onChange={(event) => {
                      form.setFieldValue(event.target.name, event.target.checked);
                    }}
                    autoComplete={"off"}
                    onInput={handleAutofillChange}
                    isInvalid={touched.active && !!errors.active}
                  />
                )}
              </Field>
              <FormControl.Feedback type="invalid">
                {errors.active}
              </FormControl.Feedback>
            </FormGroup>
            <FormGroup className="mb-3" controlId="guard-name-account-form">
              <FormLabel>Categoría</FormLabel>
              <Field
                name="category_id"
                isInvalid={touched.guard_name && !!errors.guard_name}
              >
                {({ field, form }) => (
                  <select
                    className={"form-control"}
                    value={field.value}
                    controlid="category_id"
                    placeholder="Categoría"
                    autoComplete={"off"}
                    onChange={(event) => {
                      form.setFieldValue(field.name, event.target.value);
                    }}
                  >
                    <option value="">Seleccione categoría</option>
                    {kioskInventoryState !== undefined && kioskInventoryState.category.map((g, idx)=> {
                      if(g.active) {
                        return <option key={`opti-${idx}`} value={g.id}>{g.name}</option>
                      }
                    }
                    )}
                  </select>
                )}
              </Field>
              <FormControl.Feedback type="invalid">
                {errors.category_id}
              </FormControl.Feedback>
            </FormGroup>

            <FormGroup className="mb-3" controlId="guard-name-account-form">
              <FormLabel>Impuesto aplicado</FormLabel>
              <Field
                name="tax_id"
                isInvalid={touched.guard_name && !!errors.guard_name}
              >
                {({ field, form }) => (
                  <select
                    className={"form-control"}
                    value={field.value}
                    controlid="tax_id"
                    placeholder="Impuesto"
                    autoComplete={"off"}
                    onChange={(event) => {
                      form.setFieldValue(field.name, event.target.value);
                    }}
                  >
                    <option value="">Seleccione Impuesto</option>
                    {kioskInventoryState !== undefined && kioskInventoryState.taxes.map((g, idx)=> {
                      if(g.active) {
                        return <option key={`opti-${idx}`} value={g.id}>{g.name}</option>
                      }
                    }
                    )}
                  </select>
                )}
              </Field>
              <FormControl.Feedback type="invalid">
                {errors.tax_id}
              </FormControl.Feedback>
            </FormGroup>
            {isSubmitting && <Spinner />}
            <Button variant="success" type="submit" disabled={isSubmitting}>
              Almacenar
            </Button>
          </Form>
        }
        }
      </Formik>

}

export default FormGuard