import { Badge, Button, Form, Spinner, Table } from 'react-bootstrap'
import React from 'react'

const TableBatches = ({ current, batchList, saveBatchInfo, saving, newRecord, setNewRecord,  editId, setEditId, editBatch})=> {
  const handleEdit = (evt) => {
    setEditId(evt.target.dataset.id)

    setNewRecord(false)
  }

  return <Table striped bordered hover responsive>
    <thead>
    <tr>
      <th>ID</th>
      <th>Marca</th>
      <th>Producto</th>
      <th>Precio</th>
      <th>Serial/Lote</th>
      <th>Stock</th>
      <th>Fecha de Vencimiento</th>
      <th>Estado</th>
      <th>Acciones</th>
    </tr>
    </thead>
    <tbody>
    {batchList.map((g, idx) => {
      return <tr key={idx}>
        <td>
          {g.id}
        </td>
        <td>
          {editId != g.id && g.brand }
          {editId == g.id && <Form.Control type="text" name={"brand"} onChange={editBatch} defaultValue={g.brand} />}
        </td>
        <td>
          {editId != g.id && g.name}
          {editId == g.id && <Form.Control type="text" name={"name"} onChange={editBatch} defaultValue={g.name} />}
        </td>
        <td>
          {editId != g.id && g.price}
          {editId == g.id && <Form.Control type="number" min={0} name={"price"} onChange={editBatch} defaultValue={g.price} />}
        </td>
        <td>
          {editId != g.id && g.serial}
          {editId == g.id && <Form.Control type="text" name={"serial"} onChange={editBatch} defaultValue={g.serial} />}
        </td>
        <td>{editId != g.id && `${g.quantity} ${g.input.measure.name}(s)`}
          {editId == g.id && <Form.Control type="number" name={"quantity"} onChange={editBatch} defaultValue={g.quantity} />}
        </td>
        <td>{editId != g.id && g.expiration_date}
          {editId == g.id && <Form.Control type="date" name={"expiration_date"} onChange={editBatch} defaultValue={g.expiration_date} />}
        </td>
        <td>
          {editId != g.id && <Badge bg={g.active=="1" ? 'success' : 'danger'}>{g.active=="1" ? 'activo' : 'inactivo'}</Badge> }
          {editId == g.id && <div>
            <label><input type="radio" name={"active"} value={1} onChange={editBatch} defaultChecked={g.active=="1"} /> <Badge bg={"success"}>Activo</Badge></label>
            <br/><label><input type="radio" name={"active"} value={0} onChange={editBatch} defaultChecked={g.active!="1"} /> <Badge bg={"danger"}>Inactivo</Badge></label>
          </div>}
        </td>
        <td>
          {editId != g.id && <Button variant={'warning'} data-id={g.id} className={'text-light  mx-1'}
                                     onClick={handleEdit}>Editar</Button>}
          {editId == g.id && <Button variant={'success'} data-id={g.id} disabled={saving} className={'text-light  mx-1'}
                                     onClick={saveBatchInfo}>{saving && <Spinner />} Guardar</Button>}

        </td>
      </tr>
    })}
    {newRecord && editId === 0 && <tr>
      <td>
        ?
      </td>
      <td>
        <Form.Control type="text" name={"brand"} placeholder={"Marca"} onChange={editBatch} />
      </td>
      <td>
        <Form.Control type="text" name={"name"} onChange={editBatch} placeholder={"Nombre"} />
      </td>
      <td>
        <Form.Control type="number" min={0} name={"price"} onChange={editBatch} placeholder={"Precio"}  />
      </td>
      <td>
        <Form.Control type="text" name={"serial"} onChange={editBatch} placeholder={"serial"} />
      </td>
      <td><Form.Control type="number" name={"quantity"} onChange={editBatch} placeholder={"cantidad en stock"} />
      </td>
      <td>
        <Form.Control type="date" name={"expiration_date"} onChange={editBatch} placeholder={"Fecha de Vencimiento"} />
      </td>
      <td>
        <div>
          <label><input type="radio" name={"active"} value={1} onChange={editBatch} defaultChecked={true} /> <Badge bg={"success"}>Activo</Badge></label>
          <br/><label><input type="radio" name={"active"} value={0} onChange={editBatch} defaultChecked={false} /> <Badge bg={"danger"}>Inactivo</Badge></label>
        </div>
      </td>
      <td>
        <Button variant={'success'} disabled={saving} className={'text-light  mx-1'}
                onClick={saveBatchInfo}>{saving && <Spinner />} Guardar</Button>

      </td>
    </tr>}
    </tbody>
  </Table>
}

export default TableBatches