import { useDispatch, useSelector } from 'react-redux'
import { useState, useCallback, useMemo, useEffect ,useRef } from 'react'
import { Container, Row, Col, Accordion, ListGroup, Form, Table, Button, Modal, Alert, Spinner,Card } from 'react-bootstrap'
import ReactToPrint from 'react-to-print';
import { Typeahead } from 'react-bootstrap-typeahead';
import axios from 'axios'
import { FaTrash } from "react-icons/fa";
import { MdPayments } from "react-icons/md";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { get as getCustomer } from '../../store/CustomerSlice';
import { get as getPayments } from '../../store/PaymentTypeSlice';
import { get as getInvoices } from '../../store/InvoicesKioskSlice';
import { getProduct, getUnits } from '../../store/KioskInventorySlice';

const KioskCaja = () => {
  const componentRef = useRef();
  const [loading, setLoading] = useState(true)
  const [lastInvoiceId, setLastInvoiceId] = useState(null)
  const [result, setResult] = useState(null)
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [productTree, setProductTree] = useState([])
  const [productCart, setProductCart] = useState([])
  const [invoiceData, setInvoiceData] = useState({})
  const [queryProd, setQueryProd] = useState("")
  const dispatch = useDispatch()
  const userState = useSelector((state) => state.user)
  const [singleSelections, setSingleSelections] = useState([]);
  const customerState = useSelector((state) => state.customers)
  const productsState = useSelector((state) => state.kioskInventory)
  const paymentTypesState = useSelector((state) => state.paymentTypes)
  const invoicesState = useSelector((state) => state.invoicesKiosk)
  const configs = useMemo(()=>({
    headers:{
      'content-type': 'application/json',
      'Authorization': `Bearer ${userState.user.token}`
    }
  }), [userState])

  const handleQuery = (evt) => {
    setQueryProd(evt.currentTarget.value)
  }

  const handleInvoice = (evt) => {
    setInvoiceData({
      ...invoiceData,
      [evt.currentTarget.name]: evt.currentTarget.value
    })
  }

  const getDataCustomer = useCallback(async ()=>{
    const response = await axios.get(process.env.REACT_APP_USER_API_URL + "/customer", configs)

    dispatch(getCustomer(response.data))
  },[dispatch, configs])

  const getDataInvoices = useCallback(async ()=>{
    const response = await axios.get(process.env.REACT_APP_USER_API_URL + "/kiosk/invoice", configs)

    dispatch(getInvoices(response.data))
  },[dispatch, configs])
  
  const getDataProducts = useCallback(async ()=>{
    const response = await axios.get(process.env.REACT_APP_USER_API_URL + "/kiosk/product", configs)

    dispatch(getProduct(response.data))
  },[dispatch, configs])
  
  const getDataPaymentsMethod = useCallback(async ()=>{
    const response = await axios.get(process.env.REACT_APP_USER_API_URL + "/payment-methods", configs)

    dispatch(getPayments(response.data))
  },[dispatch, configs])

  const getDataUnits = useCallback(async ()=>{
    const response = await axios.get(process.env.REACT_APP_USER_API_URL + "/kiosk/products/unit", configs)
    dispatch(getUnits(response.data))
  },[dispatch, configs])

  const addToCart = (evt) =>{
    const id = evt.currentTarget.dataset.id
    if(singleSelections.length === 0){
      alert("Selecciona el cliente antes de iniciar la facturación")
      return 
    }
    const unitaAvailable = productsState.units.filter((u)=>{
      const valid1 = parseInt(id) === parseInt(u.product_id)  
      const valid2 = u.active===1 
      const valid3 = (u.expiration===null || new Date(u.expiration) >= new Date())
      const valid4 = !productCart.some((ut)=> ut.id === u.id)
      const validS = u.sold !==1
      return valid1 && valid2 && valid3 && valid4 && validS
    })
    if(unitaAvailable.length < 1){
      alert("Producto no disponible")
    }else{
      let prdInfo = productsState.products.find((p)=>{
        return p.id == unitaAvailable[0].product_id
      })

      setProductCart([
        ...productCart,
        {
          ...unitaAvailable[0],
          product_info: prdInfo
        }
      ])
    }
    
  }

  const removeUnit = (evt) => {
    const id = evt.currentTarget.dataset.id
    setProductCart([
      ...productCart.filter((u)=>u.id != id)
    ])
  }
  const resetCart = () => {
    setProductCart([])
  }
  const payInvoice = async () => {
    setLoading(true)
    const request = {
      'customer_id': singleSelections[0].id,
      'payed': true,
      'payment_code': invoiceData.payment_code,
      'payment_type_id': invoiceData.payment_type_id,
      'units' : productCart.map((unit)=> {
        return {
          'kiosk_units_id': unit.id,
          'price': unit.product_info.sale_price
        }
      })
    }
    try{
      const result = await axios.post(process.env.REACT_APP_USER_API_URL + "/kiosk/invoice", request,configs)
      setResult(<Alert variant="success">
        Factura Pagada
      </Alert>)
      setLastInvoiceId(result.data.id)
      await getDataProducts()
      await getDataUnits()
      await getDataInvoices()
      setLoading(false)
      setProductCart([])
      setInvoiceData({})
      setSingleSelections([])
      handleClose()
    }catch(error){
      setResult(<Alert variant="danger">
        No fue posible registrar la compra!
      </Alert>)
    }
  }

  const closeInvoice = ()=>{
    setLastInvoiceId(null)
    setResult(null)
  }

  useEffect(()=>{
    setLoading(true)
    let promise = getDataCustomer(userState.user.token)
    promise.then(()=>{
      getDataProducts()
      getDataUnits().then((r)=>{
        setProductTree("x")
        setProductTree("")
      })
      getDataPaymentsMethod()
      setLoading(false)
    })
  },[getDataCustomer, getDataProducts, getDataUnits, getDataPaymentsMethod,userState.user.token])

  useEffect(()=>{
    const prod3 = productsState.products.reduce((n,o)=>{
      if(o.category.active){
        if(n[o.category.name] === undefined
          && o.active 
        ){
          n[o.category.name] = []
        }
        if(productsState.units.some((u)=>{
          const valid1 = parseInt(u.product_id) === parseInt(o.id)  
          const valid4 = o.active===1 
          const valid2 = u.active===1 
          const validS = u.sold !==1
          const valid3 = (u.expiration===null || new Date(u.expiration) >= new Date())
          return valid1 && valid2 && valid3 && valid4 && validS
        })){
          if(queryProd){
            if(o.code.includes(queryProd) || o.name.toLowerCase().includes(queryProd.toLowerCase()) || o.category.name.toLowerCase().includes(queryProd.toLowerCase())){
              n[o.category.name].push(o)  
            }
          }else{
            n[o.category.name].push(o)
          }
        }
      }
      return n
    }, {})
    setProductTree(prod3)
  },[productsState, queryProd])


  if(loading){
    return <>
      <h3 className={"mb-4 mt-2"}>Caja</h3>
      <hr />
      <Spinner animation="grow" />
    </>
  }

  if(lastInvoiceId){
    const currentInvoice = invoicesState.invoices.find((i)=>i.id === lastInvoiceId)
    
    return <div>
    <h3 className={"mb-4 mt-2"}>Caja</h3>
    <hr />
    {result}
    <div className="container w-50 m-auto"  ref={componentRef}>
    <Card className="w-100">
      <Card.Body>
        <Card.Title>Factura de Compra # {currentInvoice.id}</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">Kiosko Campo Verde Centro Vacacional</Card.Subtitle>
        <div className="row">
          <div className="col">
            <Card.Text className="h4 mt-4">Cliente: {currentInvoice.customer.name} {currentInvoice.customer.last_name}</Card.Text>
            <Card.Text style={{fontSize:"13px"}} className="h6 text-muted">CC: {currentInvoice.customer.dni}</Card.Text>
            <Card.Text style={{fontSize:"13px"}} className="h6 text-muted">Email: {currentInvoice.customer.email}</Card.Text>
          </div>
          <div className="col">
            <Card.Text className="h6 mt-4">Fecha de compra: {new Date(currentInvoice.created_at).toLocaleDateString()}</Card.Text>
            <Card.Text style={{fontSize:"13px"}} className="h6 text-muted">Método de Pago: {currentInvoice.payment_type.name}</Card.Text>
            <Card.Text style={{fontSize:"13px"}} className="h6 text-muted">Referencia de Pago: {currentInvoice.payment_code}</Card.Text>
          </div>
        </div>
        <Table responsive hover className="mt-4">
            <thead>
              <tr>
                <th>Serial</th>
                <th>Producto</th>
                <th>Precio Unitario</th>
                <th>Cantidad</th>
                <th>Precio Total</th>
              </tr>
            </thead>
            <tbody>
              {currentInvoice.details.reduce((fu, u)=>{
                let idx = fu.findIndex((ut)=>ut.product_id == u.product_id );
                if(idx >= 0){
                  fu[idx].qty+=1
                }else{
                  fu.push({...u, qty:1})
                }
                return fu
              },[]).map((u)=>{
                console.log(u)
                return <tr key={u.id}>
                    <td>{u.kiosk_unit.product.code}-{u.kiosk_unit.code_complement}</td>
                    <td>{u.kiosk_unit.product.name}</td>
                    <td>$ {parseFloat(u.price)}</td>
                    <td>{u.qty}</td>
                    <td>$ {parseFloat(u.price) * u.qty}</td>
                  </tr>
              })}
            </tbody>
          </Table>
          <div className="d-flex justify-content-around">
          <ReactToPrint
              trigger={() => <Card.Link>Imprimir</Card.Link>}
              content={() => componentRef.current}
            />
            
            <Card.Link onClick={closeInvoice}>Nueva Compra</Card.Link>
          </div>
      </Card.Body>
    </Card>
    </div>
    </div>
  }

  return <>
    <h3 className={"mb-4 mt-2"}>Caja</h3>
    <hr />
    <Container fluid>
      <Row className="mb-4">
        <Col xs={2}>Cliente:</Col>
        <Col> 
          <Typeahead
            id="basic-typeahead-single"
            labelKey={(option) => `${option.name} ${option.last_name} [${option.dni}]`}
            onChange={setSingleSelections}
            name="customer_id"
            options={customerState.customers.filter((c)=>c.active)}
            placeholder="Seleccione Cliente"
            selected={singleSelections}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={4}>
        <Form.Control type="search" id="q_term" className=" mb-2" onInput={handleQuery} placeholder="Producto a agregar"/>
        <Accordion defaultActiveKey="0">
          {Object.entries(productTree).map((c,idx)=>{
            if(c[1].length > 0){
              return <Accordion.Item key={idx} eventKey={idx}>
              <Accordion.Header>{c[0]}</Accordion.Header>
              <Accordion.Body>
              <ListGroup>
                {c[1].map((product)=>{
                  return <ListGroup.Item key={product.id} action onClick={addToCart} data-id={product.id}>
                    [{product.code}] {product.name} ({`\$${parseInt(product.sale_price)}`}))
                  </ListGroup.Item>
                })}
              </ListGroup>
              </Accordion.Body>
            </Accordion.Item>}
          })}
    </Accordion>
        </Col>
        <Col>
          <Table responsive bordered hover>
            <thead>
              <tr>
                <th>Serial</th>
                <th>Producto</th>
                <th>Precio Unitario</th>
                <th>Cantidad</th>
                <th>Precio Total</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
            {productCart.reduce((fu, u)=>{
                let idx = fu.findIndex((ut)=>ut.product_id == u.product_id );
                if(idx >= 0){
                  fu[idx].qty+=1
                }else{
                  fu.push({...u, qty:1})
                }
                return fu
              },[]).map((u)=>{
                return <tr key={u.id}>
                    <td>{u.product_info.code}-{u.code_complement}</td>
                    <td>{u.product_info.name}</td>
                    <td>$ {parseFloat(u.product_info.sale_price)}</td>
                    <td>{u.qty}</td>
                    <td>$ {parseFloat(u.product_info.sale_price)*parseInt(u.qty)}</td>
                    <td><Button variant="danger" data-id={u.id} onClick={removeUnit}><FaTrash /></Button></td>
                  </tr>
              })}
              {productCart.length >0 && <tr>
                
                <td colSpan="3">Total</td>
                <td>{productCart.length}</td>
                <td>$ {productCart.reduce((t, u)=>{
                  return parseFloat(u.product_info.sale_price) + t
                },0)}</td>
                <td><Button variant="success" size="sm" className="m-2" onClick={handleShow}><MdPayments /> Facturar</Button> <Button variant="danger" className="m-2" size="sm" onClick={resetCart}><FaTrash /> Cancelar</Button></td>

              </tr>}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Desea Facturar?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>Método de Pago</Form.Label>
          <Form.Select aria-label="Default select example" onChange={handleInvoice} name="payment_type_id">
              <option value="">Seleccione método de pago</option>
              {paymentTypesState.paymentTypes.map((pt)=>{
                if(pt.active){
                  return <option key={pt.id} value={pt.id}>{pt.name}</option>
                }
              })}
          </Form.Select>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Referencia / Observaciones</Form.Label>
            <Form.Control onInput={handleInvoice} type="text" name="payment_code"/>
          </Form.Group>
      </Form.Group>
      <Alert variant={"success"}>
          Valor a Pagar: <strong>${productCart.reduce((t, u)=>{
                  return parseFloat(u.product_info.sale_price) + t
                },0)}</strong>
          <br/>Cantidad Productos: <strong>{productCart.length}</strong>
        </Alert>
        {result}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} >
            Cancelar
          </Button>
          <Button variant="success" onClick={payInvoice} disabled={!(invoiceData.payment_type_id && invoiceData.payment_code )}>
          <MdPayments /> Facturar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  </>
}

export default KioskCaja