import { useSelector } from 'react-redux'
import { useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import Categories from './Categories'
import TypePage from './Types'
import MeasurePage from './Measures'
import MeasureConversionPage from './MeasureConversions'
import InventoryListPage from './InventoryList'

const Inventory = () => {
  const userState = useSelector((state) => state.user)
  const [key, setKey] = useState('category');


  return <>
    <h3 className={"mb-4 mt-2"}>Inventario</h3>
    <hr />
    <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="mb-3"
    >
      <Tab eventKey="profile" title="Inventario de Materias primas">
        <InventoryListPage />
      </Tab>
      <Tab eventKey="category" title="Categorías">
        <Categories />
      </Tab>
      <Tab eventKey="type" title="Tipos">
        <TypePage />
      </Tab>
      <Tab eventKey="measures" title="Unidades de medidas">
        <MeasurePage />
      </Tab>
      <Tab eventKey="measures-conversion" title="Tabla de conversión de unidades">
        <MeasureConversionPage />
      </Tab>
    </Tabs>
  </>
}

export default Inventory