import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  paymentTypes: [] ,
}

export const PaymentTypeSlice = createSlice({
  name: 'paymentTypes',
  initialState,
  reducers: {
    get: (state, action) => {
      state.paymentTypes = action.payload
    },
    save: (state, action) => {
      let indexpaymentTypes = state.paymentTypes.findIndex((g)=>(g.id === action.payload.id))
      state.paymentTypes[indexpaymentTypes] = action.payload
    },
    reset: (state, action) => {
      state.paymentTypes = []
    },
  },
})

export const { get, save, reset } = PaymentTypeSlice.actions
export default PaymentTypeSlice.reducer