import {  Container, Spinner,  Tabs, Tab } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useMemo, useState } from 'react'
import axios from 'axios'
import { saveBatch } from '../../../store/InventorySlice'
import CreateButton from '../../../components/CreateButton'
import { AiFillCheckCircle } from 'react-icons/ai'
import { MdError } from 'react-icons/md'
import ToastAlerts from '../../../components/ToastAlerts'
import TableBatchs from './Table'

const ListBatch =(props) =>{
  const dispatch = useDispatch()
  const permissionsState = useSelector((state) => state.inventory)
  const userState = useSelector((state) => state.user)
  const [editId, setEditId] = useState(0)
  const [batchData, setBatchData] = useState({
    "input_id": props.current.id,
    "active":"1"
  } )
  const [batchList , setBatchList] = useState([] )
  const [batchListConsumed , setBatchListConsumed] = useState([] )
  const [batchListInactive , setBatchListInactive] = useState([] )
  const [batchListExpired , setBatchListExpired] = useState([] )
  const [newRecord , setNewRecord] = useState(false )
  const [saving , setSaving] = useState(false )
  const [toastData, setToastData] = useState({ title:"", message:"", variant:"success" })
  const [showToast, setShowToast] = useState( false )
  const [key, setKey] = useState('ready');

  const configs = useMemo(()=>({
    headers:{
      'content-type': 'application/json',
      'Authorization': `Bearer ${userState.user.token}`,
      'Accept': `*/*`,
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE',
      'Access-Control-Allow-Headers': 'Content-Type',
      withCredentials: true,
    }
  }), [userState])
  const editBatch = async (evt) => {
    let name = evt.target.name
    await setBatchData({
      ...batchData,
      [name]: evt.target.value
    })
  }
const setNewRecordWrapper = (newRecord) => {
    setNewRecord(newRecord)
    setBatchData({
      "input_id": props.current.id
    } )
}
  const saveBatchInfo = async () => {
    setSaving(true)
    try {
      let res
      if (editId === 0) {
        res = await axios.post(process.env.REACT_APP_USER_API_URL + "/inventory-batch", batchData, configs)
      } else {
        res = await axios.put(process.env.REACT_APP_USER_API_URL + "/inventory-batch/" + editId, batchData, configs)
      }
        await dispatch(saveBatch(res.data))
        await props.getDataBatch().then(()=>{
          setToastData({
            title: <><AiFillCheckCircle className={"text-success fs-4"} /> Cambio Exitoso</>,
            message: 'Proceso ejecutado satisfactoriamente!',
            variant: 'success',
          })
          setShowToast(true)
        })
        setEditId(0)
        setNewRecord(false)
        setBatchData({
          "input_id": props.current.id
        } )
    }catch (ex){
      setToastData({
        title: <><MdError className={"text-danger fs-4"} /> El Cambio no fue exitoso</>,
        message: 'Se presentó un error en el proceso! '+ex ,
        variant: 'danger',
      })
      setShowToast(true)
    }
    setSaving(false)
  }

  useEffect(()=>{
    let listBatches = permissionsState.batch.ready.filter((b)=> (parseInt(b.input_id) === parseInt(props.current.id)))
    setBatchList(listBatches)
    let listBatchesConsumed = permissionsState.batch.consumed.filter((b)=> parseInt(b.input_id) === parseInt(props.current.id))
    setBatchListConsumed(listBatchesConsumed)
    let listBatchesInactive = permissionsState.batch.inactive.filter((b)=> (parseInt(b.input_id) === parseInt(props.current.id)))
    setBatchListInactive(listBatchesInactive)
    let listBatchesExpired = permissionsState.batch.expired.filter((b)=> (parseInt(b.input_id) === parseInt(props.current.id)))
    setBatchListExpired(listBatchesExpired)
  },[permissionsState, props])

  if(props.loading){
    return <Container className={"text-center justify-content-center"}>
      <Spinner className={"text-center"} animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </Container>
  }
  return <>
    <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="mb-3"
    >
      <Tab eventKey="ready" title="Stock Disponible">
        <TableBatchs
          batchList={batchList}
          saveBatchInfo={saveBatchInfo}
          saving={saving}
          current={props.current}
          newRecord={newRecord}
          setNewRecord={setNewRecordWrapper}
          editId={editId}
          setEditId={setEditId}
          editBatch={editBatch}
        />
      </Tab>
      <Tab eventKey="consumed" title="Stock Consumido">
        <TableBatchs
          batchList={batchListConsumed}
          saveBatchInfo={saveBatchInfo}
          saving={saving}
          current={props.current}
          newRecord={newRecord}
          setNewRecord={setNewRecordWrapper}
          editId={editId}
          setEditId={setEditId}
          editBatch={editBatch}
        />
      </Tab>
      <Tab eventKey="inactive" title="Stock Inactivo">
        <TableBatchs
          batchList={batchListInactive}
          saveBatchInfo={saveBatchInfo}
          saving={saving}
          current={props.current}
          newRecord={newRecord}
          setNewRecord={setNewRecordWrapper}
          editId={editId}
          setEditId={setEditId}
          editBatch={editBatch}
        />
      </Tab>
      <Tab eventKey="expired" title="Stock Vencido">
        <TableBatchs
          batchList={batchListExpired}
          saveBatchInfo={saveBatchInfo}
          saving={saving}
          current={props.current}
          newRecord={newRecord}
          setNewRecord={setNewRecordWrapper}
          editId={editId}
          setEditId={setEditId}
          editBatch={editBatch}
        />
      </Tab>
    </Tabs>

    <ToastAlerts {...toastData} show={showToast} setShowToast={setShowToast}/>
    {!newRecord && <CreateButton position={'absolute'} clickEvent={() => {
      setEditId(0)
      setNewRecord(true)
    }} />}
  </>
}

export default ListBatch