import { Button } from 'react-bootstrap'
import { BsPlusLg } from 'react-icons/bs'
import { BiRefresh } from 'react-icons/bi'

const CreateButton = (props) => {
  return <Button variant="success" size={"lg"} className={props.position==="absolute"?"new-button-absolute":"new-button-fixed"} onClick={props.clickEvent}><BsPlusLg /></Button>
}

const ReloadButton = (props) => {
  return <Button variant="warning" size={"lg"} className={props.position==="absolute"?"reload-button-absolute text-light":"reload-button-fixed text-light"} onClick={props.clickEvent}><BiRefresh /></Button>
}

export {CreateButton, ReloadButton}

export default CreateButton