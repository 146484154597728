import { Accordion, Button, Table } from 'react-bootstrap'
import React, { useState } from 'react'
import FormAddSteps from './FormAddStep'

const ListSteps =({ recipe, handleSubmitStep }) =>{
  const [step, setStep] = useState({
    recipe_id: recipe.id,
  })
  const handleEdit = (evt) => {
    const index = evt.target.dataset.index;
    setStep(recipe.recipe_steps[index]);
  };
  const handleSubmit = () => {
    handleSubmitStep(step)
  }


  return <>
    <Accordion className={"mb-3"}>
      <Accordion.Item eventKey="0">
        <Accordion.Header>{step.id !== undefined? "Editar":"Registrar"} Paso de la receta</Accordion.Header>
        <Accordion.Body>
          <FormAddSteps handleSubmit={handleSubmit} recipeStep={step} recipeId={recipe.id} setStep={setStep} />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
    <Table striped bordered hover responsive>
    <thead>
    <tr>
      <th>Paso</th>
      <th>Descripción</th>
      <th>Creado</th>
      <th>Acciones</th>
    </tr>
    </thead>
    <tbody>
    {recipe.recipe_steps.map((g, idx) => (<tr key={idx}>
      <td>{idx + 1}</td>
      <td>{g.description}</td>
      <td>{new Date(Date.parse(g.created_at)).toLocaleDateString()}</td>
      <td><Button variant={"warning"} data-index={idx} className={"text-light"} onClick={handleEdit}>Editar</Button>
      </td>
    </tr>))}
    </tbody>
  </Table>
    </>
}

export default ListSteps