import React, { useCallback, useEffect, useMemo, useState } from 'react'
import * as Yup from 'yup'
import { Button, FormControl, FormGroup, FormLabel, Spinner } from 'react-bootstrap'
import { Field, Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { getTypes } from '../../../store/InventorySlice'

const FormGuard = (props) => {
  const guardState = useSelector((state) => state.inventory)
  const dispatch = useDispatch()
  const userState = useSelector((state) => state.user)
  const [formValues, setFormValues] = useState({
    name: props.name,
    input_type_id: props.input_type_id,
  });

  const configs = useMemo(()=>({
    headers:{
      'content-type': 'application/json',
      'Authorization': `Bearer ${userState.user.token}`
    }
  }), [userState])

  const getData = useCallback(async ()=>{
    const response = await axios.get(process.env.REACT_APP_USER_API_URL + "/inventory-type-input", configs)

    dispatch(getTypes(response.data))
  },[dispatch, configs])

  useEffect(()=>{
    getData(userState.user.token)
  },[getData,userState.user.token])


  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Valor requerido").max(20, "Nombre debe ser máximo de 20 caracteres"),
    input_type_id: Yup.string().required("Valor requerido")
  });
  const handleAutofillChange = (event) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value
    })
  }

  const handleSubmit = async (values, { setSubmitting , isSubmitting}) => {
    setSubmitting(true)
    setFormValues(values)
    await props.handleSubmit(values)
    setSubmitting(false)
  }

  return <Formik
        initialValues={formValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, isSubmitting, errors, touched }) =>
        {
          return <Form onSubmit={handleSubmit}>
            <FormGroup className="mb-3" controlId="login-form">
              <FormLabel>Nombre de Categoría</FormLabel>
              <FormControl
                type="text"
                as={Field}
                name="name"
                controlid="name"
                placeholder="Nombre"
                autoComplete={"off"}
                onInput={handleAutofillChange}
                isInvalid={touched.name && !!errors.name}
              />
              <FormControl.Feedback type="invalid">
                {errors.name}
              </FormControl.Feedback>
            </FormGroup>
            <FormGroup className="mb-3" controlId="guard-name-account-form">
              <FormLabel>Tipo de Categoría</FormLabel>
              <Field
                name="input_type_id"
                isInvalid={touched.guard_name && !!errors.guard_name}
              >
                {({ field, form }) => (
                  <select
                    className={"form-control"}
                    value={field.value}
                    controlid="input_type_id"
                    placeholder="Tipo de Categoría"
                    autoComplete={"off"}
                    onChange={(event) => {
                      form.setFieldValue(field.name, event.target.value);
                    }}
                  >
                    <option value="">Seleccione un tipo</option>
                    {guardState !== undefined && guardState.type.map((g, idx)=> (<option key={`opti-${idx}`} value={g.id}>{g.name}</option>))}
                  </select>
                )}
              </Field>
              <FormControl.Feedback type="invalid">
                {errors.guard_name}
              </FormControl.Feedback>
            </FormGroup>
            {isSubmitting && <Spinner />}
            <Button variant="success" type="submit" disabled={isSubmitting}>
              Almacenar
            </Button>
          </Form>
        }
        }
      </Formik>

}

export default FormGuard