import { Button, Form, InputGroup, Spinner, Table } from 'react-bootstrap'
import React, { useMemo, useState } from 'react'
import ToastAlerts from '../../components/ToastAlerts'
import CreateButton from '../../components/CreateButton'
import {  useSelector } from 'react-redux'
import axios from 'axios'
import { AiFillCheckCircle } from 'react-icons/ai'
import { MdError } from 'react-icons/md'

const ListIngredients =({ recipe, getRecipes }) =>{
  const inventoryState = useSelector((state) => state.inventory)
  const userState = useSelector((state) => state.user)
  const [toastData, setToastData] = useState({ title:"", message:"", variant:"success" })
  const [showToast, setShowToast] = useState( false )
  const [newRecord , setNewRecord] = useState(false )
  const [editId, setEditId] = useState(0)
  const [ingredient, setIngredient] = useState({
    recipe_id: recipe.id,
  })
  const [saving , setSaving] = useState(false )
  const configs = useMemo(()=>({
    headers:{
      'content-type': 'application/json',
      'Authorization': `Bearer ${userState.user.token}`,
      'Accept': `*/*`,
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE',
      'Access-Control-Allow-Headers': 'Content-Type',
      withCredentials: true,
    }
  }), [userState])
  const saveIngredient = async () => {
    setSaving(true)
    try {
      if (editId === 0) {
        await axios.post(process.env.REACT_APP_USER_API_URL + "/kitchen-recipes-ingredient", ingredient, configs)
      } else {
        await axios.put(process.env.REACT_APP_USER_API_URL + "/kitchen-recipes-ingredient/" + editId, ingredient, configs)
      }
      await getRecipes().then(()=>{
        setToastData({
          title: <><AiFillCheckCircle className={"text-success fs-4"} /> Cambio Exitoso</>,
          message: 'Proceso ejecutado satisfactoriamente!',
          variant: 'success',
        })
        setShowToast(true)
      })
      setEditId(0)
      setNewRecord(false)
      setIngredient({
        "recipe_id": recipe.id
      } )
    }catch (ex){
      setToastData({
        title: <><MdError className={"text-danger fs-4"} /> El Cambio no fue exitoso</>,
        message: 'Se presentó un error en el proceso! '+ex ,
        variant: 'danger',
      })
      setShowToast(true)
    }
    setSaving(false)
  }

  const handleEdit = (evt) => {
    setEditId(evt.target.dataset.id)
    setIngredient({
      recipe_id: recipe.id,
    })
    setNewRecord(false)
  }
  const editIngredient = (evt) => {
    let name = evt.target.name
    setIngredient({
      ...ingredient,
      [name]: evt.target.value
    })
  }

  return <>
    <Table striped bordered hover responsive>
    <thead>
    <tr>
      <th>Cantidad</th>
      <th>Ingrediente</th>
      <th>Creado</th>
      <th>Acciones</th>
    </tr>
    </thead>
    <tbody>
    {recipe.recipe_ingredients.map((g, idx) => <tr key={idx}>
      <td>
        {parseInt(editId) !== parseInt(g.id) && `${g.quantity} ${g.inventory_measure.name}(s)`}
        {parseInt(editId) === parseInt(g.id) && <InputGroup className="mb-3">
          <Form.Control type="number" name={"quantity"} onChange={editIngredient} defaultValue={g.quantity} />

          <select
            className={"form-control"}
            name="measure_id"
            controlid="measure_id"
            defaultValue={g.measure_id}
            placeholder="Unidad de Medida"
            autoComplete={"off"}
            onChange={editIngredient}
          >
            <option value="">Seleccione una medida</option>
            {inventoryState !== undefined && inventoryState.measure.map((q, idx)=> <option key={`opti-${idx}`} value={q.id}>{q.name}</option>)}
          </select>
        </InputGroup>
        }

      </td>
      <td>
        {parseInt(editId) !== parseInt(g.id) && g.inventory_input.name}
        {parseInt(editId) === parseInt(g.id) && <InputGroup className="mb-3">
          <select
            className={"form-control"}
            controlid="input_id"
            name="input_id"
            defaultValue={g.input_id}
            placeholder="Ingrediente"
            autoComplete={"off"}
            onChange={editIngredient}
          >
            <option value="">Seleccione una medida</option>
            {inventoryState !== undefined && inventoryState.input.map((q, idx)=> {
              if(q.active === 1){
                return <option key={`opti-${idx}`} value={q.id}>{q.name}</option>
              }
            })}
          </select>
        </InputGroup>
        }
      </td>
      <td>{new Date(Date.parse(g.created_at)).toLocaleDateString()}</td>
      <td>
        {parseInt(editId) !== parseInt(g.id) && <Button variant={"warning"} data-id={g.id} className={"text-light"} onClick={handleEdit}>Editar</Button>}
        {parseInt(editId) === parseInt(g.id) && <Button variant={'success'} data-id={g.id} disabled={saving} className={'text-light  mx-1'}
                                   onClick={saveIngredient}>{saving && <Spinner />} Guardar</Button>}

      </td>
    </tr>)}
    {newRecord && editId === 0 && <tr>
      <td>
        <InputGroup className="mb-3">
          <Form.Control type="number" name={"quantity"} onChange={editIngredient} />

          <select
            className={"form-control"}
            name="measure_id"
            controlid="measure_id"
            placeholder="Unidad de Medida"
            autoComplete={"off"}
            onChange={editIngredient}
          >
            <option value="">Seleccione una medida</option>
            {inventoryState !== undefined && inventoryState.measure.map((q, idx)=> <option key={`opti-${idx}`} value={q.id}>{q.name}</option>)}
          </select>
        </InputGroup>

      </td>
      <td>
         <InputGroup className="mb-3">
          <select
            className={"form-control"}
            controlid="input_id"
            name="input_id"
            placeholder="Ingrediente"
            autoComplete={"off"}
            onChange={editIngredient}
          >
            <option value="">Seleccione una medida</option>
            {inventoryState !== undefined && inventoryState.input.map((q, idx)=> <option key={`opti-${idx}`} value={q.id}>{q.name}</option>)}
          </select>
        </InputGroup>

      </td>
      <td>{(new Date()).toLocaleDateString()}</td>
      <td>
         <Button variant={'success'} disabled={saving} className={'text-light  mx-1'}
                                   onClick={saveIngredient}>{saving && <Spinner />} Guardar</Button>

      </td>
    </tr>
    }
    </tbody>

  </Table>
    <ToastAlerts {...toastData} show={showToast} setShowToast={setShowToast}/>
    {!newRecord && <CreateButton position={'absolute'} clickEvent={() => {
      setEditId(0)
      setNewRecord(true)
      setIngredient({
        "recipe_id": recipe.id
      })
    }} />}
    </>
}

export default ListIngredients