import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  invoices: [],
}

export const KioskCajaSlice = createSlice({
  name: 'kioskcaja',
  initialState,
  reducers: {
    getInvoices: (state, action) => {
      state.invoices = action.payload
    },
    saveInvoices: (state, action) => {
      let index = state.invoices.findIndex((g)=>(g.id === action.payload.id))
      state.invoices[index] = action.payload
    },
    reset: (state, action) => {
      state.invoices = []
    },
  },
})

export const {
  getInvoices,
  saveInvoices,
  reset
} = KioskCajaSlice.actions
export default KioskCajaSlice.reducer